import "./App.css";
import EmailForm from "./EmailForm";
import Telemetry from "./components/telemetry";

function App() {
    return (
        <Telemetry>
            <div>
                <EmailForm />
            </div>
        </Telemetry>
    );
}

export default App;