import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

//Import all translation files
import translationEnglish from "./locales/en/translations.json";
import translationFrench from "./locales/fr/translations.json";

//---Using translation
const resources = {
    en: {
        translation: translationEnglish,
    },
    fr: {
        translation: translationFrench,
    },
};

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        fallbackLng: "en",        
        interpolation: {
            escapeValue: false, // react already safes from xss
        },
        resources        
    });

i18n.languages = ['en', 'fr'];

export default i18n;
