import React, { useState } from 'react';
import { useTranslation, getI18n } from 'react-i18next';
import { Loader } from './components/Loader';
import './EmailForm.css';
import config from './config';  

function EmailForm() {
  const [email, setEmail] = useState('');
  const { t } = useTranslation();
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [canSubmit, setCanSubmit] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleEmailChange = (e: React.FormEvent<HTMLInputElement>) => {
    let emailValue = e.currentTarget.value;
    let invalidEmailValue = !emailValue.match(/^[\w-.]+@([\w-]+\.)+[\w-]{2,63}$/);

    setEmail(emailValue);
    setInvalidEmail(invalidEmailValue);
    setCanSubmit(!invalidEmailValue);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setCanSubmit(false);
    setIsLoading(true);

    let endpointUrl: URL = new URL(config.api.getUpnEndpointUrl);

    fetch(endpointUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email, language: getI18n().language }),
    })
      .then((response) => response.json())
      .then((data) => {
        if(data.Type === 2)
          window.location.replace(data.Message);
        else if (data.Type === 1) {
          setError(data.Message);
        }
        else {
          setEmailSent(true);
        }
        
        setIsLoading(false);
      })
      .catch((error) => {
        setError('message-error');
        setIsLoading(false);
      });
  };

  const handleErrorClick = () => {
      setError('');
      setEmail('');
  }
  
  const renderFormContent = () => {
    if (error) {
      return (
        <React.Fragment>
          <div className="form-item">
            <p>{t(error)}</p>
          </div>
          <div className="form-item form-item--center">
            <button className="button" id="error-button" onClick={handleErrorClick}>{t('error-button')}</button>
          </div>
        </React.Fragment>
      )
    }

    if (emailSent) {
      return (
        <div className="form-item">
          <p>{t('message-success')}</p>
        </div>
      )
    }

    return (
      <React.Fragment>
        <div className="form-item">
          <p>{t('description')}</p>
        </div>
        <div className="input">
          <div className="input-container input-container-email">
            <div className={`input${(invalidEmail ? ' input--invalid input--dirty' : '')}`}>
              <label className="input-label">{t('email-label')}</label>
              <div className="input-container input-container-emailOrPhoneNumber">
                <input className="input-field" id="emailOrPhoneNumber" type="text" name="emailOrPhoneNumber"
                  value={email} placeholder="Email" onChange={handleEmailChange} />
              </div>
            </div>
          </div>
        </div>
        <div className="form-item form-item--center">
          <input className={`button${(canSubmit ? '' : ' button--disabled')}`} id="submit-button" type="submit" value={t('email-send-button')} disabled={!canSubmit} />
        </div>
      </React.Fragment>
    )
  }

  return (
      <React.Fragment>
          <div className="clubmed">
              <div className="container">
                  <h1 className="logo">
                      <svg
                          className="logo-svg"
                          style={{ maxWidth: 35 }}
                          preserveAspectRatio="xMidYMid meet"
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 500 687.9"
                      >
                          <path d="M429.9 461.8l-70.1 70.1 70.1 70.1 70.1-70.1-70.1-70.1zm44.6-442.7c-3.2-15.9-41.4 6.4-124.2 79.6 31.8 35 47.8 15.9 76.4 6.4C449 143.3 455.4 324.8 318.5 379c3.2-181.5 6.4-375.8 6.4-375.8-3.2-3.2-6.4-3.2-9.6-3.2-38.2 6.4-105.1 70.1-156.1 127.4 28.7 47.8 63.7 3.2 92.4-6.4 0 6.4-6.4 140.1-3.2 277.1C108.3 407.6 130.6 121 136.9 86c3.2-31.8-89.2 35-136.9 76.4 15.9 25.5 35 22.3 60.5 12.7-9.6 127.4 79.6 245.2 187.9 242 3.2 133.8 19.1 261.1 57.3 270.7 6.4 0 9.6-133.8 12.7-286.6C570.1 324.8 474.5 19.1 474.5 19.1z"></path>
                      </svg>
                  </h1>
                  <form
                      className="form-container"
                      id="send-recovery-password-form"
                      name=""
                      method="post"
                      noValidate={undefined}
                      onSubmit={handleSubmit}
                  >
                      <h3 className="title">{t("title")}</h3>
                      {renderFormContent()}
                  </form>
              </div>
          </div>
          <Loader isVisible={isLoading} label={t('loading')} />
      </React.Fragment>
  );
}

export default EmailForm;