export interface ApiConfig {
    getUpnEndpointUrl: string
}

export interface ObservabilityConfig {
    connectionString: string
}

export interface AppConfig {
    api: ApiConfig
    observability: ObservabilityConfig
}

const config: AppConfig = {
    api: {
        getUpnEndpointUrl: process.env.REACT_APP_API_ENDPOINT_URL ?? 'http://localhost:7022/api/GetUPN' 
    },
    observability: {
        connectionString: process.env.REACT_APP_APPLICATIONINSIGHTS_CONNECTION_STRING ?? ''
    }
}

export default config;